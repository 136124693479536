import { useContentLoaded } from '@/hooks/useContentLoaded'
import { APPLE_PAY_SCRIPT, FEATURE_TOGGLES } from '@/services/Configuration'
import Script, { ScriptProps } from 'next/script'

const isApplePayEnabled = () => (
  !!FEATURE_TOGGLES?.applePayEnabled || (
    typeof window !== 'undefined'
    && new URLSearchParams(window.location.search).get('forceApplePayOn') === 'true'
  )
)

const ApplePayScript: React.FC<{
  strategy?: ScriptProps['strategy']
}> = ({
  strategy = 'afterInteractive',
}) => {
  const contentLoaded = useContentLoaded()

  if ((!contentLoaded && strategy !== 'beforeInteractive') || !isApplePayEnabled()) {
    return null
  }

  return (
    <Script
      strategy={strategy}
      data-testid="apple-pay-script"
      src={APPLE_PAY_SCRIPT}
    />
  )
}

export default ApplePayScript
